import React, { useState, useEffect } from "react";
// import Header from "../../../Components/Header/Header"
import SearchResultPage from "../../templates/search-results-template";
// import Footer from "../../../Components/Footer/Footer"
// import {
//     parseSearchUrl,
//     savedSearchParams,
//     propertyH1
// } from "../../../Components/common/site/utils";

import { UserObjectStoreProvider, useAuthState, getUserobjectData } from "@myaccount/website-service";

const SearchResultLettings = (props) => {

    useEffect(() => {
        if (isAuthenticated) {
          const getUserObjects = async () => {
              try {
                  const userObjects = await getUserobjectData()
                  setUserObjects(userObjects.data.data)
              } catch (e) {
                  console.log("e:", e)
              }
          }
          getUserObjects()
        }
    }, [])

    const [userObjects, setUserObjects] = useState({});
    const { state: authState, services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    // const searchParams = parseSearchUrl("sales", fullpath);

    return (
        <React.Fragment>
            {/* <Header /> */}
            <SearchResultPage 
                locationname="/properties-to-rent/" 
                pcategorytype="residential" 
                ptype="lettings" 
                ptypetag="to-rent" 
                //pstatustype="To Let" 
                pstatustype= {['To Let']}
                status = "To Let"
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                userObjects={userObjects}
            />
            {/* <Footer /> */}
        </React.Fragment>
    );
};

// export default SearchResultLettings;

const SearchResultComp = (props) => {
    return(
        <UserObjectStoreProvider>
            <SearchResultLettings {...props} />
        </UserObjectStoreProvider>
    )
}


export default SearchResultComp;

